import React from "react";
import Card from "react-bootstrap/Card";
import { ImArrowRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            While <span className="purple">Sidharth </span>
            is a master strategist and programmer, he is only human. 
            The best way to use his skillset would be to deploy him as a <span className="purple"> Backend Developer.</span>
            <br />Currently, he is employed at PayPal, working mostly with Java on backend services and infrastructure. 
            <br />
            <br />
            Aside from this, he can be found distracted by 
          </p>
          <ul>
            <li className="about-activity">
              <ImArrowRight /> Weightlifting
            </li>
            <li className="about-activity">
              <ImArrowRight /> Video Games
            </li>
            <li className="about-activity">
              <ImArrowRight /> Music
            </li>
          </ul>

          <p style={{ color: "#C7C7C7" }}>
            "Maybe that's what Sidharth is about. Not winning, but failing, and getting back up. Knowing he'll fail, fail a thousand times, but still won't give up"{" "}
          </p>
          <footer className="blockquote-footer">Someone, about me</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
