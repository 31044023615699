import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
                I'm Sidharth, and this is my website. 
              <br />
              <br />
                This website was made to host some of my basic information, and in the future some projects I am working/have worked on. 
              <br />
              <br />
              <ul>
                <li>
                  <i>
                    I mostly code in  <b className="purple"> Java </b> and <b className="purple"> Python</b>. However, as a software engineer, I can adapt to any tech on the fly to solve whatever problem that needs solving.
                  </i>
                </li>
                <li>
                  <i>
                    Enjoy challenges in the realms of  <b className="purple">microservices</b> and <b className="purple">data analytics</b> 
                  </i>
                </li>
                <li>
                  <i>
                    I am  <b className="purple">vengeance</b>. I am <b className="purple">the night</b>. I am <b className="purple">BATMAN</b>. 
                  </i>
                </li>
              </ul>
                Everything in here has a story and a price. One thing I have learned after 24 years - you never know <i>WHAT</i> is going to come through that door. 
              <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              For my thoughts on anything from <span className="purple">centering a div</span> and <span className="purple">multithreading</span> to the <span className="purple">AI uprising</span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/sr7sidharth"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/sr7sidharth"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/themnades"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
